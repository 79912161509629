<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Node')" name="node">
          <div>
            <el-form-item
              prop="biometrics_node.data.biometrics_action"
              :label="__('Action')"
            >
              <el-select
                v-model="nodeToBind.biometrics_node.data.biometrics_action"
                style="width: 100%;"
                :placeholder="__('Please select an action')"
                value-key="id"
                default-first-option
                filterable
                clearable
                ref="actionElSelect"
                @input="changeNodeSubmitAttemptToFalse"
              >
                <el-option :label="__('Enroll')" value="enrol"></el-option>
                <el-option :label="__('Verify')" value="verify"></el-option>
                <el-option :label="__('Delete')" value="delete"></el-option>
                <el-option
                  :label="__('Quality Assurance')"
                  value="quality_assurance"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="biometrics_node.data.save_audio"
              id="save_audio"
              v-if="!(isActionQualityAssurance || isActionDelete)"
            >
              <el-switch
                v-model="nodeToBind.biometrics_node.data.save_audio"
              ></el-switch>
              <span
                style="margin-left: 10px; font-size: 20px; vertical-align: bottom"
                >{{ __("Save Recorded Utterances") }}</span
              >
              <el-tooltip :content="saveAudioHelpText" placement="top">
                <i style="padding-left: 5px" class="el-icon-info"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="biometrics_node.data.voice_print_type">
              <span slot="label">
                {{ __("Voice Print Type") }}
                <el-tooltip :content="voicePrintTypeInfo" placement="top">
                  <i style="padding-left: 5px" class="el-icon-info"></i>
                </el-tooltip>
              </span>

              <el-select
                v-model="nodeToBind.biometrics_node.data.voice_print_type"
                style="width: 100%;"
                :placeholder="__('Please select a voice print type')"
                default-first-option
                value-key="id"
                filterable
                ref="actionElSelect"
              >
                <el-option
                  :label="__('Text Independent')"
                  value="ti"
                ></el-option>
                <el-option :label="__('Phrase')" value="phrase"></el-option>
                <el-option :label="__('Digit')" value="digit"></el-option>
                <el-option :label="__('Date')" value="date"></el-option>
              </el-select>
            </el-form-item>
            <template v-if="isActionVerify">
              <el-form-item class="utterance is-required">
                <span slot="label">{{ __("Verification Utterance") }}</span>
                <biometric-utterance
                  :value="verify_prompt"
                  :task_id="task_id"
                  :prompt-path="getPromptPath(verifyPromptConst)"
                  :strict-audio-variable="isStrictAudioVariable"
                  @update-value="
                    handleUpdatePrompt(verifyPromptConst, ...arguments)
                  "
                  @reset-validation="
                    resetPrompt(verifyPromptConst, ...arguments)
                  "
                  :key="verifyPromptConst"
                ></biometric-utterance>
              </el-form-item>
            </template>

            <template v-if="isActionEnrol">
              <el-form-item class="utterance is-required">
                <span slot="label">{{ __("First Utterance") }}</span>
                <biometric-utterance
                  :value="enrol_first_prompt"
                  :task_id="task_id"
                  :prompt-path="getPromptPath(enrolPromptConst.FIRST)"
                  :strict-audio-variable="isStrictAudioVariable"
                  @update-value="
                    handleUpdatePrompt(enrolPromptConst.FIRST, ...arguments)
                  "
                  @reset-validation="
                    resetPrompt(enrolPromptConst.FIRST, ...arguments)
                  "
                  :key="enrolPromptConst.FIRST"
                ></biometric-utterance>
              </el-form-item>

              <el-form-item class="utterance is-required">
                <span slot="label">{{ __("Second Utterance") }}</span>
                <biometric-utterance
                  :value="enrol_second_prompt"
                  :task_id="task_id"
                  :prompt-path="getPromptPath(enrolPromptConst.SECOND)"
                  :strict-audio-variable="isStrictAudioVariable"
                  @update-value="
                    handleUpdatePrompt(enrolPromptConst.SECOND, ...arguments)
                  "
                  @reset-validation="
                    resetPrompt(enrolPromptConst.SECOND, ...arguments)
                  "
                  :key="enrolPromptConst.SECOND"
                ></biometric-utterance>
              </el-form-item>

              <el-form-item class="utterance is-required">
                <span slot="label">{{ __("Third Utterance") }}</span>
                <biometric-utterance
                  :value="enrol_third_prompt"
                  :task_id="task_id"
                  :prompt-path="getPromptPath(enrolPromptConst.THIRD)"
                  :strict-audio-variable="isStrictAudioVariable"
                  @update-value="
                    handleUpdatePrompt(enrolPromptConst.THIRD, ...arguments)
                  "
                  @reset-validation="
                    resetPrompt(enrolPromptConst.THIRD, ...arguments)
                  "
                  :key="enrolPromptConst.THIRD"
                ></biometric-utterance>
              </el-form-item>
            </template>
            <div v-if="isActionQualityAssurance">
              <el-form-item
                :label="__('Audio Variable')"
                prop="biometrics_node.data.quality_assurance.audio_variable_id"
                ref="audioVariableIdRef"
              >
                <el-select
                  v-model="
                    nodeToBind.biometrics_node.data.quality_assurance
                      .audio_variable_id
                  "
                  filterable
                  style="width: 100%"
                  :placeholder="__('Select Audio Variable')"
                  id="qa_audio_variable_id"
                >
                  <el-option
                    v-for="audioVariable in audioVariables"
                    :key="audioVariable.variable_id"
                    :label="audioVariable.variable_name"
                    :value="audioVariable.variable_id"
                  >
                  </el-option>
                </el-select>
                <div class="info">
                  <div style="margin-left: 5px; display: flex">
                    <div style="word-break: break-word">
                      This is the variable you wish to run the quality check on.
                    </div>
                  </div>
                </div>
              </el-form-item>

              <el-form-item
                prop="biometrics_node.data.quality_assurance.run_on"
              >
                <el-radio-group
                  v-model="
                    nodeToBind.biometrics_node.data.quality_assurance.run_on
                  "
                >
                  <el-radio-button label="enrol">{{
                    __("Run On Enrollment")
                  }}</el-radio-button>
                  <el-radio-button label="verify">{{
                    __("Run On Verification")
                  }}</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item
                :label="__('Expected Transcript')"
                prop="biometrics_node.data.quality_assurance.expected_transcript"
              >
                <input-variable-popper
                  :value="expectedTranscript"
                  @input="updatedExpectedTranscript"
                  :is-text-area="true"
                  placeholder="Enter the expected transcription content"
                  key="expected_transcript"
                  id="expected_transcript"
                  force-reinitialize
                />
              </el-form-item>
            </div>
            <el-form-item
              :label="__('Enroll ID')"
              prop="biometrics_node.data.enrol_id"
              v-if="isActionDelete || isActionVerify"
            >
              <el-select
                v-model="nodeToBind.biometrics_node.data.enrol_id"
                v-loading="variableLoading"
                style="width: 100%"
                filterable
                clearable
              >
                <el-option
                  :value="variable.variable_id"
                  :label="variable.variable_name"
                  v-for="variable in singleValuedAndSecureVariables"
                  :key="variable.variable_id"
                >
                  <div>
                    {{ variable.variable_name }}
                    <i
                      v-if="variable['variable_type'] === 'secure'"
                      class="el-icon-lock"
                      style="padding-left: 5px"
                    ></i>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="__('Event Handler')" name="event_handler">
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <el-form-item
                prop="biometrics_node.data.user_input_nodes_setting.data.no_input_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noInputEventHandlerCanvas"
                  :count="
                    nodeToBind.biometrics_node.data.user_input_nodes_setting
                      .data.no_input_count
                  "
                  :eventHandlerLabel="__('No Input Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no input event handler canvas')
                  "
                  @update-event-handler="setNoInputEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoInputEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
              <el-form-item
                prop="biometrics_node.data.user_input_nodes_setting.data.no_match_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noMatchEventHandlerCanvas"
                  :count="
                    nodeToBind.biometrics_node.data.user_input_nodes_setting
                      .data.no_match_count
                  "
                  :eventHandlerLabel="__('No Match Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no match event handler canvas')
                  "
                  @update-event-handler="setNoMatchEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoMatchEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Results')" name="results">
          <el-form-item
            prop="biometrics_node.data.variable_rules"
            :label="__('Return Values')"
          >
            <return-values
              :key="componentKey"
              :return-value="returnValue"
              :labels="enrolDisplayValue"
              @update-return-value="handleUpdateReturnValue"
            ></return-values>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import ReturnValues from "@/views/build/callflow/components/node-type-forms/components/ReturnValues";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers";
import BiometricUtterance from "@/views/build/callflow/components/BiometricUtterance";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import { eventHandlerNameValidation } from "@/utils/formValidationRules";

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false,
  variable_type: "single_value"
};

const eventHandlerCanvasInitialize = {
  canvas_name: "",
  canvas_id: -1,
  msg: ""
};

import {
  PROMPT_TYPE,
  PROMPT_FIELDS,
  ENROL_PROMPT,
  VERIFY_PROMPT,
  OLD_KEYS,
  ENROL_RET_VAL,
  ENROL_DISPLAY_VAL,
  QUALITY_ASSURANCE
} from "@/constants/biometricsNodes";

export default {
  components: {
    InputVariablePopper,
    BiometricUtterance,
    EventHandlers,
    ReturnValues
  },
  mixins: [BaseNode],
  data() {
    const enrolPromptTypeValidator = (rule, value, callback) => {
      if (this.isActionEnrol && _.isEmpty(value)) {
        callback(
          // eslint-disable-next-line
                __("Enrollment prompt type is required.")
        );
      } else {
        callback();
      }
    };

    const enrolPromptTextValidator = (rule, value, callback) => {
      if (
        this.isActionEnrol &&
        this[rule.promptName].prompt_type === PROMPT_TYPE.RECORDING &&
        _.isEmpty(value)
      ) {
        callback(
          // eslint-disable-next-line
              __("Enrollment prompt text is required.")
        );
      } else {
        callback();
      }
    };

    const enrolPromptDurationValidator = (rule, value, callback) => {
      if (
        this.isActionEnrol &&
        this[rule.promptName].prompt_type === PROMPT_TYPE.RECORDING &&
        _.isEmpty(value)
      ) {
        callback(
          // eslint-disable-next-line
              __("Record duration is required.")
        );
      } else {
        callback();
      }
    };

    const enrolPromptVarValidator = (rule, value, callback) => {
      if (
        this.isActionEnrol &&
        this[rule.promptName].prompt_type === PROMPT_TYPE.AUDIO_VARIABLE &&
        !value
      ) {
        // eslint-disable-next-line
          callback(__("Audio variable is required"));
      } else {
        callback();
      }
    };

    const verifyPromptTextValidator = (rule, value, callback) => {
      if (
        this.isActionVerify &&
        this.verify_prompt.prompt_type === PROMPT_TYPE.RECORDING &&
        _.isEmpty(value)
      ) {
        callback(
          // eslint-disable-next-line
              __("Verify prompt text is required.")
        );
      } else {
        callback();
      }
    };

    const verifyPromptTypeValidator = (rule, value, callback) => {
      if (this.isActionVerify && _.isEmpty(value)) {
        callback(
          // eslint-disable-next-line
              __("Verification type is required.")
        );
      } else {
        callback();
      }
    };

    const verifyPromptVarValidator = (rule, value, callback) => {
      if (
        this.isActionVerify &&
        this.verify_prompt.prompt_type === PROMPT_TYPE.AUDIO_VARIABLE &&
        !value
      ) {
        // eslint-disable-next-line
          callback(__("Audio variable is required"));
      } else {
        callback();
      }
    };

    const verifyPromptDurationValidator = (rule, value, callback) => {
      if (
        this.isActionVerify &&
        this.verify_prompt.prompt_type === PROMPT_TYPE.RECORDING &&
        _.isEmpty(value)
      ) {
        callback(
          // eslint-disable-next-line
              __("Record duration is required.")
        );
      } else {
        callback();
      }
    };

    const qaAudioVariableSelected = (rule, value, callback) => {
      if (
        !this.nodeToBind.biometrics_node.data.quality_assurance
          .audio_variable_id &&
        this.isActionQualityAssurance
      ) {
        callback(__("Audio variable is required"));
      } else {
        callback();
      }
    };

    const validateNoInputEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "input");
    };
    const validateNoMatchEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "match");
    };

    return {
      rules: {
        biometrics_node: {
          data: {
            biometrics_action: [
              {
                required: true,
                message: __("Please select an action"),
                trigger: "blur"
              }
            ],
            quality_assurance: {
              audio_variable_id: [
                {
                  required: true,
                  validator: qaAudioVariableSelected,
                  trigger: "change"
                }
              ]
            },
            enrol_first_prompt: {
              prompt_text: [
                {
                  validator: enrolPromptTextValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.FIRST
                }
              ],
              prompt_type: [
                {
                  validator: enrolPromptTypeValidator,
                  trigger: "blur"
                }
              ],
              audio_variable_id: [
                {
                  validator: enrolPromptVarValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.FIRST
                }
              ],
              record_duration: [
                {
                  validator: enrolPromptDurationValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.FIRST
                }
              ]
            },
            enrol_second_prompt: {
              prompt_text: [
                {
                  validator: enrolPromptTextValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.SECOND
                }
              ],
              prompt_type: [
                {
                  validator: enrolPromptTypeValidator,
                  trigger: "blur"
                }
              ],
              audio_variable_id: [
                {
                  validator: enrolPromptVarValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.SECOND
                }
              ],
              record_duration: [
                {
                  validator: enrolPromptDurationValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.SECOND
                }
              ]
            },
            enrol_third_prompt: {
              prompt_text: [
                {
                  validator: enrolPromptTextValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.THIRD
                }
              ],
              prompt_type: [
                {
                  validator: enrolPromptTypeValidator,
                  trigger: "blur"
                }
              ],
              audio_variable_id: [
                {
                  validator: enrolPromptVarValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.THIRD
                }
              ],
              record_duration: [
                {
                  validator: enrolPromptDurationValidator,
                  trigger: "blur",
                  promptName: ENROL_PROMPT.THIRD
                }
              ]
            },
            verify_prompt: {
              prompt_text: [
                {
                  validator: verifyPromptTextValidator,
                  trigger: "blur"
                }
              ],
              prompt_type: [
                {
                  validator: verifyPromptTypeValidator,
                  trigger: "blur"
                }
              ],
              audio_variable_id: [
                {
                  validator: verifyPromptVarValidator,
                  trigger: "blur"
                }
              ],
              record_duration: [
                {
                  validator: verifyPromptDurationValidator,
                  trigger: "blur"
                }
              ]
            },
            save_audio: [
              {
                required: true,
                trigger: "blur"
              }
            ],
            user_input_nodes_setting: {
              data: {
                no_input_canvas_id: [
                  {
                    required: true,
                    validator: validateNoInputEventHandler,
                    trigger: "change"
                  }
                ],
                no_match_canvas_id: [
                  {
                    required: true,
                    validator: validateNoMatchEventHandler,
                    trigger: "change"
                  }
                ]
              }
            }
          }
        }
      },
      activeTab: "node",
      // returnValue: [],
      enrolReturnValue: [
        {
          variable_id: "",
          rule_value: ENROL_RET_VAL.ID
        },
        {
          variable_id: "",
          rule_value: ENROL_RET_VAL.RES
        },
        {
          variable_id: "",
          rule_value: ENROL_RET_VAL.MSG
        },
        {
          variable_id: "",
          rule_value: ENROL_RET_VAL.DES
        }
      ],
      verifyReturnValue: [
        {
          variable_id: "",
          rule_value: "verify_result"
        },
        {
          variable_id: "",
          rule_value: "verify_score"
        },
        {
          variable_id: "",
          rule_value: "verify_imposter_score"
        },
        {
          variable_id: "",
          rule_value: "verify_result_description"
        }
      ],
      deleteReturnValue: [
        {
          variable_id: "",
          rule_value: "delete_result"
        },
        {
          variable_id: "",
          rule_value: "delete_message"
        },
        {
          variable_id: "",
          rule_value: "delete_result_description"
        }
      ],
      qaReturnValue: [
        {
          variable_id: "",
          rule_value: "qa_result"
        },
        {
          variable_id: "",
          rule_value: "qa_response"
        },
        {
          variable_id: "",
          rule_value: "qa_result_description"
        }
      ],
      tabStructure: {
        node: [
          "biometrics_node.data.biometrics_action",
          "biometrics_node.data.enrol_first_prompt.prompt_type",
          "biometrics_node.data.enrol_first_prompt.prompt_text",
          "biometrics_node.data.enrol_first_prompt.record_duration",
          "biometrics_node.data.enrol_first_prompt.audio_variable_id",
          "biometrics_node.data.enrol_second_prompt.prompt_type",
          "biometrics_node.data.enrol_second_prompt.prompt_text",
          "biometrics_node.data.enrol_second_prompt.record_duration",
          "biometrics_node.data.enrol_second_prompt.audio_variable_id",
          "biometrics_node.data.enrol_third_prompt.prompt_type",
          "biometrics_node.data.enrol_third_prompt.prompt_text",
          "biometrics_node.data.enrol_third_prompt.record_duration",
          "biometrics_node.data.enrol_third_prompt.audio_variable_id",
          "biometrics_node.data.enrol_id",
          "biometrics_node.data.verify_prompt.prompt_type",
          "biometrics_node.data.verify_prompt.prompt_text",
          "biometrics_node.data.verify_prompt.record_duration",
          "biometrics_node.data.verify_prompt.audio_variable_id",
          "biometrics_node.data.quality_assurance.audio_variable_id",
          "biometrics_node.data.quality_assurance.run_on",
          "biometrics_node.data.quality_assurance.expected_transcript"
        ],
        event_handler: [
          "biometrics_node.data.user_input_nodes_setting.data.no_input_canvas_id",
          "biometrics_node.data.user_input_nodes_setting.data.no_match_canvas_id"
        ],
        results: []
      },
      enrolDisplayValue: ENROL_DISPLAY_VAL,

      componentKey: 0,
      formHasError: false,
      enrolPromptConst: ENROL_PROMPT,
      verifyPromptConst: VERIFY_PROMPT,
      promptPrefix: "biometrics_node.data",
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize)
    };
  },
  computed: {
    ...mapState("variables", {
      variableLoading: state => state.loading
    }),

    ...mapState("prompts", {
      prompts: state => state.prompts,
      promptsLoading: state => state.loading
    }),

    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList"
    }),

    ...mapGetters("variables", {
      audioVariables: "audioVariables",
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    }),
    default_prompt_type() {
      return this.isStrictAudioVariable
        ? PROMPT_TYPE.AUDIO_VARIABLE
        : PROMPT_TYPE.RECORDING;
    },
    expectedTranscript() {
      return (
        this.nodeToBind.biometrics_node.data.quality_assurance
          .expected_transcript || ""
      );
    },

    verify_prompt() {
      return this.nodeToBind.biometrics_node.data.verify_prompt;
    },
    enrol_third_prompt() {
      return this.nodeToBind.biometrics_node.data.enrol_third_prompt;
    },
    enrol_second_prompt() {
      return this.nodeToBind.biometrics_node.data.enrol_second_prompt;
    },
    enrol_first_prompt() {
      return this.nodeToBind.biometrics_node.data.enrol_first_prompt;
    },
    isActionEnrol() {
      return this.nodeToBind.biometrics_node.data.biometrics_action === "enrol";
    },
    isActionVerify() {
      return (
        this.nodeToBind.biometrics_node.data.biometrics_action === "verify"
      );
    },
    isActionDelete() {
      return (
        this.nodeToBind.biometrics_node.data.biometrics_action === "delete"
      );
    },
    isActionQualityAssurance() {
      return (
        this.nodeToBind.biometrics_node.data.biometrics_action ===
        "quality_assurance"
      );
    },
    isStrictAudioVariable() {
      return this.task_type === "workflow";
    },
    noInputEventHandlerCanvas() {
      return this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data
        .no_input_canvas_id;
    },
    noMatchEventHandlerCanvas() {
      return this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data
        .no_match_canvas_id;
    },
    voicePrintTypeInfo() {
      let voice_print_type = this.nodeToBind.biometrics_node.data
        .voice_print_type;

      /* eslint-disable */
      switch (voice_print_type) {
        case "phrase":
          return __("A phrase of at least 4 words (preferably more). Enrollment should have at least 3 utterances. Verification should have the same word sequence as enrollment.");
        case "date":
          return __("Enrollment should have at least 3 utterances. Verification should be the same date as the enrollment.");
        case "digit":
          return __("Sequences of at least 7 digits (preferably 8 to 10). Enrollment should have at least 3 utterances. Verification should have the same sequence of digits as the enrollment.");
        case "ti":
          return __("Enrollment utterance(s) should contain at least 10 seconds of net conversational speech. Verification utterance should contain at least 4 seconds of net conversational speech");
        default:
          return "";
      }
      /* eslint-enable */
    },

    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },

    getOptionsInitiators() {
      return ["{{", "[["];
    },

    returnValue() {
      if (this.isActionEnrol) {
        return this.enrolReturnValue;
      } else if (this.isActionVerify) {
        return this.verifyReturnValue;
      } else if (this.isActionDelete) {
        return this.deleteReturnValue;
      } else if (this.isActionQualityAssurance) {
        return this.qaReturnValue;
      } else {
        return [];
      }
    },

    generateRowLimit() {
      let row_limit = Array.from(Array(300), (_, i) => (i + 1).toString());
      return ["max-limit"].concat(row_limit);
    },

    saveAudioHelpText() {
      /* eslint-disable */
      return this.nodeToBind.biometrics_node.data.save_audio
        ? __("Disable this option if you do not want the audio file to be saved on the server")
        : __("Enable this option if you want the audio file to be saved on the server");
      /* eslint-enable */
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.biometrics_node)
    ) {
      this.$set(this.nodeToBind, "biometrics_node", {});
      this.$set(this.nodeToBind.biometrics_node, "data", {});
      this.$set(
        this.nodeToBind.biometrics_node.data,
        "biometrics_action",
        "enrol"
      );
      this.$set(
        this.nodeToBind.biometrics_node.data,
        "voice_print_type",
        "digit"
      );
      this.initializeEnrolPrompt();
      this.initializeVerifyPrompt();
      this.initializeQualityAssurancePrompt();
      this.$set(this.nodeToBind.biometrics_node.data, "variable_rules", {});
      this.$set(
        this.nodeToBind.biometrics_node.data.variable_rules,
        "data",
        []
      );
      this.$set(this.nodeToBind, "node_type", NODE_TYPES.BIOMETRICS.NODE_TYPE);
      this.$set(this.nodeToBind.biometrics_node.data, "save_audio", true);
      this.initializeUserInputNodesSettings();
    } else {
      if (
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data
          .no_input_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
          "no_input_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noInputEventHandlerCanvas
          })
        );
      }

      if (
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data
          .no_match_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
          "no_match_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noMatchEventHandlerCanvas
          })
        );
      }
      this.addPrompts();
      this.initializeReturnValues();
    }
    this.initializePrompts();
  },
  methods: {
    ...mapActions("prompts", {
      getPrompts: "getPrompts"
    }),
    changeNodeSubmitAttemptToFalse() {
      this.attemptedToSubmit = false;
      _.map(this.errorBag, (notification, message) => {
        this.errorBag[message].close();
        delete this.errorBag[message];
      });

      this.errorBag = {};
    },
    updatedExpectedTranscript(val) {
      this.nodeToBind.biometrics_node.data.quality_assurance.expected_transcript = val;
    },
    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          this.$set(row, "variable_name", option.label);
          this.$set(row, "variable_id", option.value);
          // this.$set(row, "msg", option.msg);
          // this.$set(row, "rule_value", row.rule_value);
        });
      };
    },

    handleUpdateText(key, value) {
      this.$set(this.nodeToBind.biometrics_node.data, key, value);
    },

    handleUpdatePrompt(prompt, key, value) {
      this.$set(this.nodeToBind.biometrics_node.data[prompt], key, value);
    },

    handleUpdateReturnValue(value) {
      if (this.isActionEnrol) {
        this.enrolReturnValue = [...value];
      } else if (this.isActionVerify) {
        this.verifyReturnValue = [...value];
      } else if (this.isActionDelete) {
        this.deleteReturnValue = [...value];
      } else if (this.isActionQualityAssurance) {
        this.qaReturnValue = [...value];
      }

      let filteredVariableRulesData = filterRowsIfSomeKeyValueIsAbsent(
        [...value],
        "variable_name"
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.variable_rules,
        "data",
        filteredVariableRulesData
      );
    },

    initializePrompts() {
      if (!this.prompts.length) {
        this.getPrompts();
      }
    },

    initializeUserInputNodesSettings() {
      this.$set(
        this.nodeToBind.biometrics_node.data,
        "user_input_nodes_setting",
        {}
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting,
        "data",
        {}
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "barge_in",
        false
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_match_count",
        2
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_input_count",
        2
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "save_audio",
        true
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "fallback_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_input_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_match_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "form_parameters",
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "confidence_level",
        0.0
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "interdigit_timeout",
        2.0
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
    },

    initializeReturnValues() {
      if (
        !_.isEmpty(this.nodeToBind.biometrics_node.data.variable_rules.data)
      ) {
        const variableRules = [
          ...this.nodeToBind.biometrics_node.data.variable_rules.data
        ];

        const assignedVariableRuleData = _.remove(variableRules, function(
          rule
        ) {
          return rule.rule_value !== "root" && _.isEmpty(rule.default_value);
        });

        const returnValue = this.returnValue.map(v => ({
          ...v,
          ...assignedVariableRuleData.find(sp => sp.rule_value === v.rule_value)
        }));

        this.handleUpdateReturnValue(returnValue);
      }
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);

      if (!this.formHasError) {
        if (!this.isActionEnrol) {
          delete nodeToCleanUp.biometrics_node.data[ENROL_PROMPT.FIRST];
          delete nodeToCleanUp.biometrics_node.data[ENROL_PROMPT.SECOND];
          delete nodeToCleanUp.biometrics_node.data[ENROL_PROMPT.THIRD];
        }

        if (!this.isActionVerify) {
          delete nodeToCleanUp.biometrics_node.data[VERIFY_PROMPT];
        }

        if (!this.isActionQualityAssurance) {
          delete nodeToCleanUp.biometrics_node.data[QUALITY_ASSURANCE];
        }
      }

      try {
        let newVar = _.find(
          nodeToCleanUp.biometrics_node.data.variable_rules.data,
          { variable_id: -1 }
        );
        if (newVar !== undefined) {
          this.newVariableCreated = true;
        }

        return _.cloneDeep(nodeToCleanUp);
      } catch (err) {
        this.$notify({
          type: "error",
          message: err.message,
          title: "Error"
        });
        this.isSubmitting = false;
        this.toggleNodeSubmit(false);
        return null;
      }
    },

    cleanUpNode() {
      this.$refs.nodeForm.validate(valid => {
        this.formHasError = !valid;
      });

      if (!this.formHasError) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        if (this.nodeToBind) {
          this.removeOldKeys();
        }
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },

    setNoInputEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "biometrics_node.data.user_input_nodes_setting.data.no_input_canvas_id"
      );
    },
    setNoMatchEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "biometrics_node.data.user_input_nodes_setting.data.no_match_canvas_id"
      );
    },
    setNoMatchEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_match_count",
        val
      );
    },
    setNoInputEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.biometrics_node.data.user_input_nodes_setting.data,
        "no_input_count",
        val
      );
    },

    resetPrompt(prompt, promptFields) {
      this.$refs.nodeForm.clearValidate(promptFields);
      if (!this[prompt].prompt_text) {
        this.handleUpdatePrompt(prompt, "prompt_text", "");
      }
      this.handleUpdatePrompt(prompt, "audio_variable_id", null);
      this.handleUpdatePrompt(prompt, "record_duration", "max-limit");
    },

    getPromptPath(promptName) {
      return this.promptPrefix.concat(".", promptName);
    },
    initializeQualityAssurancePrompt() {
      this.$set(this.nodeToBind.biometrics_node.data, "quality_assurance", {});
      this.$set(
        this.nodeToBind.biometrics_node.data.quality_assurance,
        "audio_variable_id",
        null
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.quality_assurance,
        "run_on",
        "enrol"
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.quality_assurance,
        "expected_transcript",
        ""
      );
    },
    initializeEnrolPrompt() {
      this.$set(this.nodeToBind.biometrics_node.data, ENROL_PROMPT.FIRST, {});
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_first_prompt,
        PROMPT_FIELDS.TEXT,
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_first_prompt,
        PROMPT_FIELDS.TYPE,
        this.default_prompt_type
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_first_prompt,
        PROMPT_FIELDS.VAR_ID,
        null
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_first_prompt,
        PROMPT_FIELDS.DURATION,
        "max-limit"
      );
      this.$set(this.nodeToBind.biometrics_node.data, ENROL_PROMPT.SECOND, {});
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_second_prompt,
        PROMPT_FIELDS.TEXT,
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_second_prompt,
        PROMPT_FIELDS.TYPE,
        this.default_prompt_type
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_second_prompt,
        PROMPT_FIELDS.VAR_ID,
        null
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_second_prompt,
        PROMPT_FIELDS.DURATION,
        "max-limit"
      );

      this.$set(this.nodeToBind.biometrics_node.data, ENROL_PROMPT.THIRD, {});
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_third_prompt,
        PROMPT_FIELDS.TEXT,
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_third_prompt,
        PROMPT_FIELDS.TYPE,
        this.default_prompt_type
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_third_prompt,
        PROMPT_FIELDS.VAR_ID,
        null
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.enrol_third_prompt,
        PROMPT_FIELDS.DURATION,
        "max-limit"
      );
    },
    initializeVerifyPrompt() {
      this.$set(this.nodeToBind.biometrics_node.data, VERIFY_PROMPT, {});
      this.$set(
        this.nodeToBind.biometrics_node.data.verify_prompt,
        PROMPT_FIELDS.TEXT,
        ""
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.verify_prompt,
        PROMPT_FIELDS.TYPE,
        this.default_prompt_type
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.verify_prompt,
        PROMPT_FIELDS.VAR_ID,
        null
      );
      this.$set(
        this.nodeToBind.biometrics_node.data.verify_prompt,
        PROMPT_FIELDS.DURATION,
        "max-limit"
      );
    },
    addPrompts() {
      if (
        !this.isActionEnrol ||
        !Object.prototype.hasOwnProperty.call(
          this.nodeToBind.biometrics_node.data,
          ENROL_PROMPT.FIRST
        ) ||
        !Object.prototype.hasOwnProperty.call(
          this.nodeToBind.biometrics_node.data,
          ENROL_PROMPT.SECOND
        ) ||
        !Object.prototype.hasOwnProperty.call(
          this.nodeToBind.biometrics_node.data,
          ENROL_PROMPT.THIRD
        )
      ) {
        this.initializeEnrolPrompt();
      }
      if (
        !this.isActionVerify ||
        !Object.prototype.hasOwnProperty.call(
          this.nodeToBind.biometrics_node.data,
          VERIFY_PROMPT
        )
      ) {
        this.initializeVerifyPrompt();
      }

      if (!this.isActionQualityAssurance) {
        this.initializeQualityAssurancePrompt();
      }
    },
    removeOldKeys() {
      let self = this;
      OLD_KEYS.forEach(function(key) {
        delete self.nodeToBind.biometrics_node.data[key];
      });
    }
  },

  watch: {
    "nodeToBind.biometrics_node.data.biometrics_action": function(val, oldVal) {
      this.nodeToBind.biometrics_node.data.save_audio = !(
        val === "delete" || val === "quality_assurance"
      );

      if (val !== oldVal) {
        this.componentKey++;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";

.tabs ::v-deep .el-tabs__item.is-active {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__item:hover {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__active-bar {
  background-color: $--color-messaging_conversation-node;
}

/*.tabs ::v-deep .el-tabs__nav-wrap::after {*/
/*  background-color: white;*/
/*}*/

.text-input ::v-deep textarea {
  resize: none;
}

.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;

    &:focus {
      border: 1px solid black;
    }
  }
}

.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}

.el-form {
  .el-form-item {
    margin-bottom: 24px;

    ::v-deep .el-form-item__label {
      font-size: 17px;
      padding-bottom: 12px;
    }

    &.utterance {
      ::v-deep .el-form-item__label {
        padding: 0 !important;
        span {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
