const RECORDING = "recording";
const AUDIO_VARIABLE = "audio_variable";

export const PROMPT_TYPE = {
  RECORDING,
  AUDIO_VARIABLE
};

const TYPE = "prompt_type";
const TEXT = "prompt_text";
const VAR_ID = "audio_variable_id";
const DURATION = "record_duration";

export const PROMPT_FIELDS = {
  TYPE,
  TEXT,
  VAR_ID,
  DURATION
};

const FIRST = "enrol_first_prompt";
const SECOND = "enrol_second_prompt";
const THIRD = "enrol_third_prompt";
export const VERIFY_PROMPT = "verify_prompt";
export const QUALITY_ASSURANCE = "quality_assurance";

export const ENROL_PROMPT = {
  FIRST,
  SECOND,
  THIRD
};

const ID = "enrol_id";
const RES = "enrol_result";
const MSG = "enrol_message";
const DES = "enrol_result_description";

export const ENROL_RET_VAL = {
  ID,
  RES,
  MSG,
  DES
};
export const ENROL_DISPLAY_VAL = {
  [ID]: "Enroll Id",
  [RES]: "Enroll Result",
  [MSG]: "Enroll Message",
  [DES]: "Enroll Result Description"
};

const VERIFICATION_TYPE = "verification_type";
const FIRST_TEXT = "enrol_first_prompt_text";
const SECOND_TEXT = "enrol_second_prompt_text";
const THIRD_TEXT = "enrol_third_prompt_text";
const VERIFY_TEXT = "verify_prompt_text";

export const OLD_KEYS = [
  VERIFICATION_TYPE,
  DURATION,
  VAR_ID,
  FIRST_TEXT,
  SECOND_TEXT,
  THIRD_TEXT,
  VERIFY_TEXT
];
