<template>
  <div>
    <el-form-item :prop="prompt_fields.prompt_type">
      <el-radio-group
        v-model="prompt_type"
        @change="resetValidation"
        style="padding: 24px 0"
        v-if="!strictAudioVariable"
      >
        <el-radio-button :label="promptTypeRecording">{{
          __("Use Recorded Audio")
        }}</el-radio-button>
        <el-radio-button :label="promptTypeAudioVariable">{{
          __("Use Audio Variable")
        }}</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <template v-if="isPromptTypeRecording">
      <el-form-item :prop="prompt_fields.prompt_text">
        <span slot="label" style="padding-bottom: 16px">{{
          __("Caller Instructions")
        }}</span>
        <input-variable-popper
          v-model="prompt_text"
          is-content-editable
          :is-text-area="false"
          include-prompts
          include-audio-variables
          :include-secure-variables="false"
          :ats="getOptionsInitiators"
          popper-class="prompt-node-popper"
          class="promptEditor"
          :popper-offset="-400"
          :placeholder="__('Enter caller instructions')"
        />
        <audio-player
          class="audio-player"
          :disabled="!prompt_text"
          @get-preview="
            generateAudio(
              'prompt_text',
              'generatingAudio',
              'promptAudioFileEnrolPromptText',
              'promptsAtAudioFileCreationEnrolPromptText'
            )
          "
          :show-reload="enrolPromptContentChanged"
          :generating-audio="generatingAudioEnrolPromptText"
          :file="promptAudioFileEnrolPromptText"
        />
      </el-form-item>
      <el-form-item :prop="prompt_fields.record_duration">
        <div style="margin-top: 15px">
          {{ __("Record Duration (seconds)") }}
        </div>
        <el-select
          v-model="duration"
          style="width: 22%;"
          default-first-option
          filterable
          :placeholder="__('Set a recording duration limit')"
        >
          <el-option
            v-for="(item, index) in generateRowLimit"
            :label="item"
            :value="item"
            :key="index"
          />
        </el-select>
      </el-form-item>
    </template>
    <template v-else-if="isPromptTypeAudioVariable">
      <el-form-item :prop="prompt_fields.audio_variable_id">
        <div style="margin-top: 20px"></div>
        <el-select
          v-model="audio_variable_id"
          filterable
          style="width: 100%"
          :placeholder="__('Select Audio Variable')"
        >
          <el-option
            v-for="audioVariable in audioVariables"
            :key="audioVariable.variable_id"
            :label="audioVariable.variable_name"
            :value="audioVariable.variable_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </template>
  </div>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import { mapGetters } from "vuex";
import { PROMPT_TYPE, PROMPT_FIELDS } from "@/constants/biometricsNodes";

export default {
  name: "BiometricUtterance",
  components: {
    AudioPlayer,
    InputVariablePopper
  },
  mixins: [GenerateAudio],
  props: {
    value: {
      type: Object,
      required: true
    },
    task_id: {
      type: [String, Number],
      required: true
    },
    promptPath: {
      required: true,
      type: String
    },
    strictAudioVariable: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      generatingAudioEnrolPromptText: false,
      promptsAtAudioFileCreationEnrolPromptText: "",
      promptAudioFileEnrolPromptText: ""
    };
  },
  methods: {
    resetValidation() {
      this.$emit("reset-validation", Object.values(this.prompt_fields));
    },
    getFieldPath(field) {
      return this.promptPath.concat(".", field);
    }
  },
  computed: {
    ...mapGetters("variables", {
      audioVariables: "audioVariables"
    }),
    getOptionsInitiators() {
      return ["{{", "[["];
    },
    enrolPromptContentChanged() {
      return (
        this.prompt_text !== this.promptsAtAudioFileCreationEnrolPromptText
      );
    },
    generateRowLimit() {
      let row_limit = Array.from(Array(300), (_, i) => (i + 1).toString());
      return ["max-limit"].concat(row_limit);
    },
    promptTypeRecording() {
      return PROMPT_TYPE.RECORDING;
    },
    isPromptTypeRecording() {
      return this.prompt_type === PROMPT_TYPE.RECORDING;
    },
    promptTypeAudioVariable() {
      return PROMPT_TYPE.AUDIO_VARIABLE;
    },
    isPromptTypeAudioVariable() {
      return this.prompt_type === PROMPT_TYPE.AUDIO_VARIABLE;
    },
    prompt_text: {
      // getter
      get: function() {
        return this.value.prompt_text;
      },
      // setter
      set: function(newValue) {
        this.$emit("update-value", PROMPT_FIELDS.TEXT, newValue);
      }
    },
    audio_variable_id: {
      // getter
      get: function() {
        return this.value.audio_variable_id;
      },
      // setter
      set: function(newValue) {
        this.$emit("update-value", PROMPT_FIELDS.VAR_ID, newValue);
      }
    },
    prompt_type: {
      // getter
      get: function() {
        return this.value.prompt_type;
      },
      // setter
      set: function(newValue) {
        this.$emit("update-value", PROMPT_FIELDS.TYPE, newValue);
      }
    },
    duration: {
      // getter
      get: function() {
        return this.value.record_duration;
      },
      // setter
      set: function(newValue) {
        this.$emit("update-value", PROMPT_FIELDS.DURATION, newValue);
      }
    },
    prompt_fields() {
      return {
        prompt_type: this.getFieldPath(PROMPT_FIELDS.TYPE),
        prompt_text: this.getFieldPath(PROMPT_FIELDS.TEXT),
        audio_variable_id: this.getFieldPath(PROMPT_FIELDS.VAR_ID),
        record_duration: this.getFieldPath(PROMPT_FIELDS.DURATION)
      };
    }
  }
};
</script>

<style scoped lang="scss">
.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;

    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
